<template>
  <div>
    <v-row>
      <v-col cols="6" md="4" lg="4">
        <v-card outlined>
          <v-card-title class="text-h4"
            ><v-icon class="text-h4">mdi-view-compact</v-icon> 
            11
            <span class="text-h6 mx-1">شركة</span>
          </v-card-title>
          <v-card-text class="d-flex justify-end">
            <vue-apex-charts
              width="100%"
              type="pie"
              height="135"
              :options="optionsPie"
              :series="optionsPie.series"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" md="4" lg="4">
        <v-card outlined>
          <v-card-title class="text-h4"
            ><v-icon class="text-h4">mdi-store</v-icon> 
            20
            <span class="text-h6 mx-1">مخزن</span>
          </v-card-title>

          <v-card-text class="d-flex justify-end">
            <vue-apex-charts
              width="100%"
              type="pie"
              height="135"
              :options="optionsPie"
              :series="optionsPie.series"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" md="4" lg="4">
        <v-card outlined>
          <v-card-title class="text-h4"
            ><v-icon class="text-h4">mdi-view-compact</v-icon> 
            11
            <span class="text-h6 mx-1">المنتجات</span>
          </v-card-title>

          <v-card-text class="d-flex justify-end">
            <vue-apex-charts
              width="100%"
              type="pie"
              height="135"
              :options="optionsPie"
              :series="optionsPie.series"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card class="transparent">
          <v-card-title> اخر عمليات الشراء </v-card-title>
          <v-card-text> لايوجد </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card outlined>
          <v-card-title> احصائيات الوارد </v-card-title>
          <v-card-text>
            <vue-apex-charts
              width="100%"
              type="bar"
              :options="options"
              :series="series"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card outlined>
          <v-card-title> احصائيات العائد </v-card-title>
          <v-card-text>
            <vue-apex-charts
              width="100%"
              type="line"
              :options="options"
              :series="series"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    VueApexCharts,
  },
  data() {
    return {
      optionsPie: {
        colors: ['#318194','#4D77A9', '#7A2969', '#D72D74'],
        customScale: 10,
        series: [44, 55, 13, 33],
        labels: ["فايزر", "باندول", "أسترا زينيكا", "ابوت"],
      },
      options: {
        chart: {
          id: "vuechart-example",
        },
        
        xaxis: {
          
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },
      series: [
        {
          name: "series-1",
          data: [30, 40, 45, 50, 49, 60, 70, 91],
        },
      ],
    };
  },
  created() {},
};
</script>

<style>
.apexcharts-legend-series {
  margin-top: 0 !important;
}
.apexcharts-legend-text {
  margin-right: 5px !important;
}
</style>